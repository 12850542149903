<template>
  <v-card
    v-if="payments"
    class="elevation-0 transparent rounded-lg"
    :disabled="loading"
  >
    <v-toolbar
      color="white"
      class=" mb-3 rounded-lg"
      style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);"
    >
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
        style="width:100%"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-select
        flat
        hide-details
        rounded
        dense
        color="primary"
        background-color="blue-grey lighten-5"
        height="36px"
        :items="keys"
        single-line
        :label="'Status'"
        v-model="sortBy"
        class="mx-2"
        style="width: 200px !important;"
      ></v-select>
      <v-btn @click="createUser = true" color="primary" elevation="0" rounded>
        <v-icon left dark class="mr-3"> mdi-plus </v-icon>{{ $t("MF.add") }}
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="searchresult"
      :loading="loading"
      flat
      :locale="this.$root.lang"
      @click:row="openDetails"
      class="rounded-lg"
    >
      <!-- body -->
      <template v-slot:item.udid="{ item }">
        <div class="font-size-lg font-weight-bolder">{{ item.udid }}</div>
      </template>
      <template v-slot:item.owner="{ item }">
        <v-list-item>
          <v-avatar color="primary" size="40">
            <v-img
              v-if="item.owner.avatar != null"
              :src="$root.apiURL + 'assets/' + item.owner.avatar.private_hash"
            ></v-img>
            <div
              v-else
              style="text-transform: uppercase;width:100%"
              class="white--text font-size-h4"
            >
              {{ item.owner.first_name.charAt(0) }}
            </div>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title>
              {{ item.owner.first_name }} {{ item.owner.last_name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item.method="{ item }">
        <div class="font-size-md">
          {{ getPaymentMethod(item.method.id).name }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div
          :class="item.status == 'pending' ? 'yellow' : 'teal'"
          class="white--text font-weight-bold darken-3 text-center p-1 px-3 rounded pushx"
          style="width:fit-content; font-size:1rem"
        >
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.amount="{ item }">
        <div class="font-size-lg font-weight-bolder">£ {{ item.amount }}</div>
      </template>
      <template v-slot:item.created_on="{ item }">
        <div class="font-size-md">
          {{
            item.created_on
              | moment(
                "timezone",
                $util.getTimezone(),
                "ddd DD/MM/YYYY hh:mm A"
              )
          }}<br />
          {{
            item.created_on
              | moment("timezone", $util.getTimezone(), "from", "now")
          }}
        </div>
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="showPayment"
      app
      temporary
      width="40vw"
      style="border-radius: 0;"
      :right="$root.lang != 'ar'"
    >
      <v-toolbar
        color="rgb(238, 240, 248)"
        style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;"
        height="60"
      >
        <v-btn
          icon
          dark
          color="primary"
          @click="
            showPayment = false;
            loadingappointment = true;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-primary">
          Payment Details
        </v-toolbar-title>
      </v-toolbar>
      <v-card style="height:calc(100vh - 128px)" v-if="paymentitem" flat>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Refrence Number</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="font-size-lg font-weight-bolder">
                {{ paymentitem.udid }}
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>By</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="font-size-lg font-weight-bolder">
                {{ paymentitem.owner.first_name }}
                {{ paymentitem.owner.last_name }}
              </div>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Via</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="font-size-lg font-weight-bolder">
                {{ getPaymentMethod(paymentitem.method.id).name }}
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div
                :class="paymentitem.status == 'pending' ? 'yellow' : 'teal'"
                class="white--text font-weight-bold darken-3 text-center p-1 px-3 rounded"
                style="width:fit-content; font-size:1rem"
              >
                {{ paymentitem.status }}
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Created On</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="text-align:right">
              {{
                paymentitem.created_on
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "ddd DD/MM/YYYY hh:mm A"
                  )
              }}<br />
              {{
                paymentitem.created_on
                  | moment("timezone", $util.getTimezone(), "from", "now")
              }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Modified On</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="text-align:right">
              {{
                paymentitem.modified_on
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "ddd DD/MM/YYYY hh:mm A"
                  )
              }}<br />
              {{
                paymentitem.modified_on
                  | moment("timezone", $util.getTimezone(), "from", "now")
              }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Amount</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="font-size-lg font-weight-bolder">
                £ {{ paymentitem.amount }}
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Payment For</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                v-if="loadingappointment"
                indeterminate
                color="primary"
              />
              <div style="text-align:right" v-else>
                <div class="listitemtitle" v-if="appointment.type == 'video'">
                  {{ $t("APPOINTMENTS.APP.vidwith") }}
                  <span v-if="appointment.doctor"
                    >{{ appointment.doctor.doctor_id.first_name }}
                    {{ appointment.doctor.doctor_id.last_name }}</span
                  >
                </div>
                <div
                  class="listitemtitle"
                  v-else-if="appointment.type == 'clinic'"
                >
                  {{ $t("APPOINTMENTS.APP.clinicwith") }}
                  <span v-if="appointment.doctor"
                    >{{ appointment.doctor.doctor_id.first_name }}
                    {{ appointment.doctor.doctor_id.last_name }}</span
                  >
                </div>
                <div class="listitemsubtitle">
                  {{
                    (appointment.date_time + "Z")
                      | moment(
                        "timezone",
                        $util.getTimezone(),
                        "ddd DD/MM/YYYY hh:mm A"
                      )
                  }}
                  {{
                    (appointment.date_time + "Z")
                      | moment("timezone", $util.getTimezone(), "from", "now")
                  }}
                </div>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <v-row class="px-3">
        <v-col>
          <v-btn block large rounded class="pushxl" outlined color="red">
            Cancel Appointment
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            large
            block
            color="teal"
            elevation="0"
            rounded
            @click="confirm(paymentitem)"
            dark
          >
            Confirm Payment
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-snackbar timeout="5000" v-model="snackbar" absolute bottom>
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: true,
      showPayment: false,
      headers: [
        { text: "ID", value: "udid" },
        { text: "By", value: "owner", sortable: false },
        { text: "Via", value: "method" },
        { text: "Status", value: "status" },
        { text: "Date", value: "created_on" },
        { text: "Amount", value: "amount" },
      ],

      payments: [],
      formTitle: "Add Payment",
      search: "",
      sortBy: "",
      keys: ["All", "Pending", "Confirmed"],
      snackbar: false,
      msg: "",
      paymentmethods: [],
      paymentitem: null,
      loadingappointment: true,
      appointment: null,
    };
  },

  async created() {
    this.$root.title = "Payments";
    //this.$root.admin.extramargin = "390px";
    //this.$root.admin.openside = true;
    this.paymentmethods = await this.$payments.getPaymentType();
  },
  watch: {
    /*"$root.admin": {
      handler() {
        this.getUsers();
      },
      deep: true,
    },*/
  },
  methods: {
    confirm(p) {
      this.$payments.confirmPayment(p.id);
      this.showPayment = false;
      this.loadingappointment = true;
      p.status = 'confirmed';
      const index = this.payments.findIndex((i) => {
          return p.id === i.id
      })
      console.log("index of payment is", index)
      if(index)
        this.payments[index] = p;
    },
    getPaymentMethod(id) {
      return this.paymentmethods.find((i) => {
        return id === i.id;
      });
    },
    openDetails(d) {
      this.paymentitem = d;
      this.showPayment = true;
      this.getapp(d.id);
    },
    async getapp(id) {
      await this.$app.getItemByPaymentID(id).then((data) => {
        console.log("app data", data.data.data);
        this.appointment = data.data.data[0];
        this.loadingappointment = false;
      });
    },
    showupdate(m) {
      this.snackbar = true;
      this.msg = m;
    },
    async getpayments() {
      console.log("getting payments");
      await this.$payments.getAll().then((data) => {
        this.loading = false;
        console.log("payments are", data.data.data);
        this.payments = data.data.data;
      });
    },
  },
  mounted() {
    this.getpayments();
  },
  computed: {
    paymentsSorted() {
      return this.payments.filter((i) => {
        return (
          !this.sortBy ||
          i.status.toLowerCase() === this.sortBy.toLowerCase() ||
          this.sortBy == "All"
        );
      });
    },
    searchresult() {
      return this.paymentsSorted.filter((i) => {
        return (
          !this.search ||
          i.udid.toLowerCase().includes(this.search.toLowerCase()) ||
          i.status.toLowerCase().includes(this.search.toLowerCase()) ||
          (i.amount + "").includes(this.search) ||
          (
            i.owner.first_name.toLowerCase() +
            " " +
            i.owner.last_name.toLowerCase()
          ).includes(this.search.toLowerCase()) ||
          i.created_on.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
};
</script>

<style></style>
